import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import routes from '../config/routes'
import Link from 'next/link'
import { useAuth } from '../providers/AuthProvider'
import onlyGuest from '../hoc/onlyGuest'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { NextSeo } from 'next-seo'
import { useForm } from 'react-hook-form'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { HeroIcons } from '../lib/utils/heroIcons'
import LanguageSelect from '../components/LanguageSelect'
import { classNames } from '../lib/utils/classNames'

function LoginPage() {
  const { t } = useTranslation('login')
  const router = useRouter()
  const [error, setError] = useState<string>()
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const { login, authWithGoogle } = useAuth()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onBlur' })

  useEffect(() => {
    if (router.query.email) {
      reset({ email: router.query.email })
    }
  }, [router.query])

  const [sendingData, setSendingData] = useState<boolean>(false)
  const onSubmitForm = async (
    data: any,
    e?: React.BaseSyntheticEvent,
  ): Promise<void> => {
    setShowPassword(false)
    try {
      e?.preventDefault()
      if (!data || sendingData) return
      setSendingData(true)
      await login(data.email, data.password)
      reset()
    } catch (e: any | AxiosError) {
      if (axios.isAxiosError(e)) {
        const axiosResponse: AxiosResponse = (e as AxiosError).response!
        if (axiosResponse?.data.message === 'Invalid user/password') {
          setError(t('errors.invalidPassword'))
          return
        }
        if (axiosResponse?.data.message === 'User account is not active') {
          setError(t('errors.accountNotActive'))
          return
        }
        if (
          axiosResponse?.data.message ===
          'You must login with your Google credentials'
        ) {
          setError(t('errors.loginWithGoogle'))
          return
        }
      }
      setError(t('errors.accountNotActive'))
    } finally {
      setSendingData(false)
    }
  }

  return (
    <>
      <NextSeo
        title={t('seo.title') || ''}
        description={t('seo.description') || ''}
      />

      <div className="flex min-h-screen items-center bg-purpleGradient px-3 py-10">
        <div className="flex min-h-full flex-1 flex-col justify-center sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-8 w-auto"
              src="/img/logos/shelters/logotype-white.svg"
              alt="Shelters"
            />
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="py-2">
              <Link href={routes.index}>
                <div className="flex font-bold text-neutralWhite">
                  <HeroIcons.ArrowLeftIcon
                    className="mr-2 h-5 w-5 items-center pt-0.5"
                    aria-hidden="true"
                  />
                  {t('backToMainMenu')}
                </div>
              </Link>
            </div>
            <div className="rounded-lg bg-white px-6 py-6 shadow sm:px-12">
              <div className="pb-6">
                <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-neutralBlack">
                  {t('title')}
                </h2>
              </div>

              <form
                className="space-y-6"
                onSubmit={handleSubmit(onSubmitForm)}
                name="LoginApp"
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t('inputs.email')}
                  </label>
                  <div className="mt-2">
                    <input
                      className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      type="email"
                      required={true}
                      autoComplete="email"
                      aria-required="true"
                      placeholder=""
                      id="email"
                      {...register('email', {
                        required: t('email') || '',
                      })}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t('inputs.password')}
                  </label>
                  <div className="relative mt-2">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      required={true}
                      autoComplete="current-password"
                      aria-required="true"
                      placeholder=""
                      id="password"
                      {...register('password', {
                        required: t('password') || '',
                      })}
                    />
                    <span
                      className="absolute inset-y-0 right-0 flex cursor-pointer select-none items-center pr-2"
                      onMouseDown={() => setShowPassword(true)}
                      onMouseUp={() => setShowPassword(false)}
                      onMouseLeave={() => setShowPassword(false)}
                      onTouchStart={() => setShowPassword(true)}
                      onTouchEnd={() => setShowPassword(false)}
                    >
                      <HeroIcons.EyeIcon
                        className={classNames(
                          'h-5 w-5',
                          showPassword
                            ? 'text-neutralGray/50'
                            : 'text-neutralGray',
                        )}
                      />
                    </span>
                  </div>
                </div>

                <div className="flex items-center justify-end">
                  <div className="text-sm leading-6">
                    <Link
                      href={routes.forgotPassword}
                      className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                      {t('buttons.forgotPassword')}
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {t('buttons.submit')}
                  </button>
                  {error && (
                    <div
                      className="relative py-2 text-xs text-red-700"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                </div>
              </form>

              <div>
                <div className="relative mt-6">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900">
                      {t('or')}
                    </span>
                  </div>
                </div>

                <div className="group relative mt-6 flex">
                  <button
                    type="button"
                    className="border-1 flex w-full items-center justify-center gap-3 rounded-md border bg-gray-50/30 px-3 py-1.5 text-white hover:bg-gray-100/30"
                    onClick={authWithGoogle}
                  >
                    <img src="/img/google.svg" width={20} alt="Google logo" />
                    <span className="text-sm font-semibold leading-6 text-neutralBlack">
                      Google
                    </span>
                  </button>
                </div>
              </div>

              <div className="mt-8">
                <p className="text-center text-sm text-gray-500">
                  {t('notAMember')}
                </p>

                <Link
                  href={routes.register}
                  className="mx-auto flex w-fit items-center font-bold leading-6 text-indigo-600 hover:text-indigo-500"
                >
                  <div className="mx-auto flex items-center underline">
                    {t('buttons.register')}
                    <HeroIcons.UserPlusIcon className="ml-1 h-5 w-5" />
                  </div>
                </Link>
              </div>
            </div>

            <div className="mt-12 flex items-center">
              <div className="mx-auto rounded-2xl bg-neutralWhite">
                <LanguageSelect />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default onlyGuest(LoginPage)

export async function getServerSideProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'login'])),
    },
  }
}
