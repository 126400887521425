import { useRouter } from 'next/router'
import { useAuth } from '../providers/AuthProvider'
import routes from '../config/routes'

export default function onlyGuest(WrappedComponent: any) {
  // eslint-disable-next-line react/display-name
  return () => {
    const { isLoggedIn } = useAuth()
    const router = useRouter()

    if (isLoggedIn) {
      const redirect = router.query.redirect as string
      if (redirect) {
        router.replace({ pathname: redirect })
      } else {
        router.replace({ pathname: routes.index })
      }
      return
    }

    return <WrappedComponent />
  }
}
